import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Layout from 'components/LandingLayout';
import metaTranslations from '../../lib/utils';
import theme from '../../themes';
import SEO from 'components/seo';
import NavbarComponent from '../../components/Landing/Navbar/Navbar';
import NavbarFeature from '../../components/Landing/NavbarFeature/NavbarFeature';
import FeatureListContainer from "../../components/Landing/FeatureListContainer/FeatureListContainer";
import { windowGlobal } from '../../config';
import FeatureHero from '../../components/Landing/FeatureHero/FeatureHero';
import BroadcastImage from '../../images/landing/messages_broadcast_image.svg';
import SendDirectMsgPinkImage from '../../images/landing/messages_send_direct_msg_pink.svg';
import AttachAndShareImage from '../../images/landing/messages_attach_share_image.svg';
import SendDirectMsgBlueImage from '../../images/landing/messages_send_direct_msg_blue.svg';
import AnnouncementsImage from '../../images/landing/messages_announcements.svg';
import SendFilesImage from '../../images/landing/messages_send_files.svg';
import MessajeHero from '../../images/landing/messages_hero_dsktp.png';
import MessajeHeroMobile from '../../images/landing/Messages_mobile_wbkg_3x.png';
import { WithTrans } from '../../language/withTrans';

const FEATURE_VIEW = 'MessagesView';
const COLOR_FOR_TEACHERS_CONTAINER = "rgb(218,77,122,0.1)";
const HERO_TITLE = "Communicate with parents securely and seamlessly";
const FOR_TEACHERS_ARRAY = [
  {
    title: "Broadcast",
    description: "Broadcast important announcements",
    image: BroadcastImage,
  },
  {
    title: "Send messages",
    description: "Send direct messages to families",
    image: SendDirectMsgPinkImage,
  },
  {
    title: "Attach & share files",
    description: "Attach & share files",
    image: AttachAndShareImage
  }
];

const FOR_PARENTS_ARRAY = [
  {
    title: "direct msg",
    description: "Have one on one conversations with your kids teacher",
    image: SendDirectMsgBlueImage,
  },
  {
    title: "virtual",
    description: "Recieve important announcements",
    image: AnnouncementsImage,
  },
  {
    title: "Send Teachers",
    description: "Send files early to teachers",
    image: SendFilesImage,
  }
];

const Messages = ({
  lang,
}) => {
  windowGlobal.localStorage.setItem('LANDING_LOCALE', lang);

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[{ name: 'og:image', content: 'https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg' }]}
      />
      <ThemeProvider theme={theme}>
        <NavbarComponent lang={'es'} />
        <NavbarFeature view={FEATURE_VIEW} />
        <FeatureHero
          title={HERO_TITLE}
          imageDesktop={MessajeHero}
          imageMobile={MessajeHeroMobile}
        />

        <FeatureListContainer
          color={COLOR_FOR_TEACHERS_CONTAINER}
          itemsForTeachers={FOR_TEACHERS_ARRAY}
          itemsForParents={FOR_PARENTS_ARRAY}
        />
      </ThemeProvider>
    </Layout>
  );
};

export default WithTrans()(Messages);
